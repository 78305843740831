import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';


// CSS in JS
const styles = {
    body: {
        fontFamily: 'Arial, sans-serif',
        zIndex: 999,
    },
    popup: {
        display: 'flex',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    popupContent: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        /*maxWidth: '600px',*/
        width: '90%',
        maxHeight: '80%',
        overflowY: 'auto',
        marginTop: '116px',
    },
    close: {
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
        gap: '10px',
    },
    gridItem: {
        borderRadius: '8px',
        overflow: 'hidden',
    },
    gridItemImg: {
        width: '100%',
        borderRadius: '8px',
        transition: 'transform 0.3s',
        cursor: 'pointer',
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
    },
    pageButton: {
        margin: '0 5px',
        padding: '10px 20px',
        cursor: 'pointer',
        borderRadius: '4px',
        backgroundColor: '#ddd',
    }, 
    lotobtn:{
        cursor: 'pointer',
    },   
};

const LotoCanva = (props) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [imageUrls, setImageUrls] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);       

    useEffect(() => {
        // Função para buscar dados da API
        const fetchImages = async (page) => {
            try {
                const response = await fetch(`https://teste-api.tvmx.com.br/multimidia-v2/links/page/${page}/limit/30`);
                const data = await response.json();
                // Extraindo URLs das imagens da resposta
                setImageUrls(data.results || []);
                setTotalPages(Math.ceil(data.total / 10)); // Supondo que o servidor retorna o total de itens
            } catch (error) {
                console.error('Erro ao buscar imagens:', error);
            }
        };

        // Chama a função de busca de imagens quando o componente é montado
        fetchImages(currentPage);
    }, [currentPage]); 

    const openPopup = () => setIsPopupOpen(true);
    const closePopup = () => setIsPopupOpen(false);
    const fileFromURL = async (url)=> {        
            const fileName = 'lotocanva';
            const file = await fileFromURL(url, fileName);
            
            // Create a DataTransfer object and add the file to it
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
        
            // Get the input element and set its files property
            const inputElement = document.getElementById('icon-button-file');
            inputElement.files = dataTransfer.files;
        
            console.log('File set on input element:', inputElement.files[0]);

      }  
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };        
    const selectImagem = async (item, setMedia2,setStartLoader, index) => {
        setStartLoader(true);
        const response = await fetch(`https://teste-api.tvmx.com.br/multimidia-v2/lotocanva/${item.id}`);     
        const data = await response.text();   
        await setMedia2(data, index, item.tmb); 
        console.log(`https://teste-api.tvmx.com.br/multimidia-v2/lotocanva/${item.id}`);
        console.log(`response: ${data}`);
        //document.getElementById('minhaimg').src = data;
        setStartLoader(false);
        closePopup();
    }
        /*alert(url)*/
    
    

    return (
        <div style={styles.body}>            
            <a onClick={openPopup} className="brand-logo">
              <img id="minhaimg" src="https://i.ibb.co/GTQK2HG/Whats-App-Image-2024-02-29-at-21-17-49.jpg" alt="Brand Logo" height="45px" style={styles.lotobtn} />
            </a>
            {isPopupOpen && (
                <div style={styles.popup} onClick={closePopup}>
                    <div style={styles.popupContent} onClick={(e) => e.stopPropagation()}>
                        <span style={styles.close} onClick={closePopup}>&times;</span>
                        <div style={styles.gridContainer}>
                            {imageUrls.map((item, index) => (
                                <div key={index} style={styles.gridItem} onClick={() => selectImagem(item, props.setMedia2, props.setStartLoader, index)} /*onClick={async (e) => {await props.setMedia2(item.tmb, index); closePopup();}}*/>
                                    <img src={item.tmb} alt={`Image ${item.id}`} style={styles.gridItemImg} />
                                </div>
                            ))}
                        </div>
                        <div style={styles.pagination}>
                            <button style={styles.pageButton} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Anterior</button>
                            <span>Página {currentPage} de {totalPages}</span>
                            <button style={styles.pageButton} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Próxima</button>
                        </div>                        
                    </div>
                </div>
            )}
        </div>
    );    
};

export default LotoCanva;
